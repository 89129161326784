<template>
  <div>
    <Head/>
    <Banner/>
    <Fotter/>
  </div>
</template>

<script>
import Head from '@/components/head.vue'
import Banner from '@/components/Banner.vue'
import Fotter from '@/components/footer.vue'

export default {
  name: 'Home',
  components: {
   Head,Banner,Fotter
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>
<style scoped>
</style>










































