<template>
  <div class="header">
    <div class="header-list">
      <div class="header-top">
        <div class="logo">
          <img  class="logo-img" src = '/assets/logo/logo.png'  alt=""></img>
          <!-- <a href="#" title="首页"></a> -->
        </div>
        <ul class="header-menu">
          <li v-for="(v, i) in titles" :key="i">
            <router-link :to="v.path">
            <span>{{ v.title }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'Head',
  props: {
    msg: String
  }
  ,
  data() {
    return {
      titles: [
        { title: '首页', path: '/' },
        { title: '关于', path: '/about' },
        // { title: '产品', path: '/product'}
      ]
    }
  }
}


</script>

<style scoped>
.header {
  width: 100vw;
  height: 70px;
  display: flex;
  position: fixed;
  background-color: rgba(0, 0, 0,0.65);
  top: 0;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
}
.logo {
  position: 50%;
}
.logo-img{
  width: 178px;
  height: 70px;
}
.header-list {
  position:relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  box-shadow: 0 5px 5px rgba(0,0,0,0.5);
}

.header-top {
  height: 70px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.header-top ul {
  flex: 0.5;
  display: flex;
  list-style: none;
}

.header-menu li {
  margin: 0 30px;
  position: relative;
}

.header-menu li span::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -10px;
  width: 0%;
  height: 100%;
  border-bottom: 5px solid rgb(54, 101, 101);
  transition: all 0.2s;
}

.header-menu li span:hover::before{
  content: '';
  width: 100%;
  left: 0%;
}

.header-menu li span {
  font-size: 20px;
  font-weight: 900;
}

.header-menu li a {
  text-decoration: none;
  color: white;
}

.header-menu li span:hover {
  color: rgb(50, 153, 157);
}


</style>
