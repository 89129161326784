<template>
    <div class="footer">
        <div class="footer-link">
            南京星玩网络科技有限公司 | 咨询时代,专业服务
        </div>
        <div>
            南京市江北新区星火路9号软件大厦A座766室 | 联系电话:13390778947
        </div>
        <div class="footer-link">
         
        </div>
        <div>
        <a class="icp-link" href="https://beian.miit.gov.cn">苏ICP备2024100739号-1</a>
        </div>
        <div>
            
        </div>
    </div>
</template>

<script setup>


</script>



<style scoped>
.footer {
    padding-top: 50px;
    margin: 0;
    width: auto;
    height: 100px;
    /* position: relative; */
    background-color:rgb(31, 27, 27);
    /* display: flex; */
    color: whitesmoke;

}

.footer-protocol {
    text-decoration: none;
}

.icp-link{
    color: #fff;
}

.icp-link{
    text-decoration: none;
}
/* .ft_zhengshuhao {
    width: 678px;
    height: 35px;
    margin: 5px 0 0 20px;
    overflow: hidden;
    border: 1px solid #626262;
    padding: 7px 0;
} */
</style>