<template>
    <swiper :modules="modules" :pagination="{ clickable: true }" :autoplay="autoplay" @swiper="onSwiper"
        @slideChange="onSlideChange"  @mouseenter="enter()" @mouseleave="leave()" :loop="true">
        <swiper-slide v-for="(item, index) in slides" :key="index">
            <img class="img" :src="item.src" alt="">
        </swiper-slide>
        <!-- <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div> -->
        <div class="swiper-pagination" :slot="pagination"></div>
    </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';
import { Pagination,Navigation, Autoplay, Scrollbar, A11y } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css'; //轮播图底面的小圆点
import 'swiper/modules/navigation/navigation.min.css'; // 左右切换
import 'swiper/modules/autoplay/autoplay.min.css'; // 自动切换
import 'swiper/modules/scrollbar/scrollbar.min.css'; // 滚动条

export default {
    name: 'Banner',
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {},
    swiperOptions: {
        effect: "fade",
        grabCursor: true,
        cubeEffect: {
            shadow: true,
            slideShadows: true,
            shadowOffset: 20,
            shadowScale: 0.94,
        },
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    },
    data() {
        return {
            isStop: false, //是否停止动画
            s1: '', //banner 
            autoplay: {
                delay: 4000, //1秒切换一次
                disableOnInteraction: true
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + '</span>';
                },
            },
            slides: [
                { src: '/assets/images/sp1.png', srct: '', name: '', desc: '连接梦想,创造乐趣', btxt: '', hrf: 'javascript:;' },
                { src: '/assets/images/sp2.png', srct: '', name: '', desc: '探索无尽的虚拟世界，畅享无穷的游戏乐趣！', btxt: '', hrf: 'javascript:;' },
            ],
            modules: [Pagination, Navigation, Autoplay, Scrollbar, A11y],
        };
    },
    created() { },
    mounted() { },
    computed: {  },
    methods: {
        // 轮播初始化-执行
        onSwiper(swiper) {
            
            this.mySwiper = swiper
        },
        // 每个slide切换执行
        onSlideChange(swiper) {
       
        },
        prevEl(e) {
            console.log('============prevEl=========');
            this.mySwiper.slidePrev()
        },
        nextEl() {
            console.log('============nextEl=========');
            this.mySwiper.slideNext()
        },
        enter() {
            this.mySwiper.autoplay.stop();
         //   this.isStop = true;
        },
        leave() {
            this.mySwiper.autoplay.start();
           // this.isStop = false;
        }
    }
}
</script>
<style scoped>
.swiper {
    top: 0;
    left: 0;
    width: 100%;
    height: 800px;
    /* position: relative;  */
    /* overflow: hidden; */
}

.swiper-slider img {
    width: 100%;
}

.swiper-button-next,
.swiper-button-prev {
    width: 40px;
    height: 80px;
    color: #d32f2f;
    background: rgba(0, 0, 0, .3);
    border: 1px solid rgba(0, 0, 0, .15);
    top: 45%;
    outline: none;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 16px;
}

.swiper-pagination {
    background: #000;
    width: 100%;
    height: 20px;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}


</style>